"use client";
import { useEffect, useState } from "react";
import { isStage } from "@/const";
import { useInteraction } from "@/helpers/useInteraction";
import { usePathname } from "next/navigation";

export const FacebookPixel = () => {
  const pathname = usePathname();

  const [FPixel, setFPixel] = useState<any>();

  const code = isStage ? "967187420534762" : "880844995871811";

  const { isInteracted } = useInteraction();

  useEffect(() => {
    if (isInteracted) {
      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(code); // facebookPixelId
          ReactPixel.pageView();
          setFPixel(ReactPixel);
        });
    }
  }, [code, isInteracted]);

  useEffect(() => {
    if (!FPixel) return;
    FPixel.pageView();
  }, [FPixel, pathname]);
  return null;
};
