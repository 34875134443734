import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store/store";

export const samplesSlice = createSlice({
  name: "samples",
  initialState: {
    list: [],
    filters: {
      idAcademicDisciplines: [],
      idPaperTypes: [],
      idAcademicLevels: [],
      idCitationStyles: [],
    },
    settings: {
      idAcademicDisciplines: [],
      idPaperTypes: [],
      idAcademicLevels: [],
      idCitationStyles: [],
      search: "",
      modalOpen: false,
    },
    filteredList: [],
  },
  reducers: {
    init(state) {
      state.filteredList = state.list;
    },
    subjectsSettings({ settings }, { payload }) {
      settings.idAcademicDisciplines = payload;
    },
    typesSettings({ settings }, { payload }) {
      settings.idPaperTypes = payload;
    },
    levelsSettings({ settings }, { payload }) {
      settings.idAcademicLevels = payload;
    },
    formatsSettings({ settings }, { payload }) {
      settings.idCitationStyles = payload;
    },
    search({ settings }, { payload }) {
      settings.search = payload;
    },
    clear({ settings }) {
      settings.idAcademicDisciplines = [];
      settings.idPaperTypes = [];
      settings.idAcademicLevels = [];
      settings.idCitationStyles = [];
      settings.search = "";
    },
    setInitialValues(state, { payload }) {
      state.list = payload.list;
      state.filteredList = payload.list;
      state.filters = payload.filters;
    },
    filter(state) {
      const { idAcademicDisciplines, idPaperTypes, idAcademicLevels, idCitationStyles, search } =
        state.settings;
      state.filteredList = state.list.filter(
        ({ idAcademicDiscipline, idPaperType, idAcademicLevel, idCitationStyle, title }) => {
          const findFilter = (arr: string[], filter: string) => {
            if (arr.length > 0) return arr.includes(filter);
            else return true;
          };
          const searchFilter = (str: string, filter: string) => {
            if (str.length > 0) return filter.toLowerCase().includes(str.toLowerCase());
            else return true;
          };
          return (
            findFilter(idAcademicDisciplines, idAcademicDiscipline) &&
            findFilter(idPaperTypes, idPaperType) &&
            findFilter(idAcademicLevels, idAcademicLevel) &&
            findFilter(idCitationStyles, idCitationStyle) &&
            searchFilter(search, title)
          );
        }
      );
    },
    modalOpen({ settings }, { payload }) {
      settings.modalOpen = payload;
    },
  },
});

export default samplesSlice.reducer;

export const sampleFiltersSelector = ({ samples }: RootState) => samples.filters;
export const sampleSettingsSelector = ({ samples }: RootState) => samples.settings;
export const sampleFilteredListSelector = ({ samples }: RootState) => samples.filteredList;
export const sampleListSelector = ({ samples }: RootState) => samples.list;
