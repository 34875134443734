"use client";
import { useEffect, useState, MouseEvent, useCallback } from "react";
import dynamic from "next/dynamic";
import { StrapiPromo } from "@/types/strapi/promo";
import { baseUrl } from "@/const";

const HolidayBannerCard = dynamic(() => import("./HolidayBannerCard"), { ssr: false });

export const HolidayBanner = () => {
  const [isInteracted, setIsInteracted] = useState(false);
  const [advertisingCampaign, setAdvertisingCampaign] = useState<StrapiPromo>();

  useEffect(() => {
    const interactedCheck = () => {
      setIsInteracted(true);
      window.removeEventListener("scroll", interactedCheck);
      document.removeEventListener("mousemove", interactedCheck);
    };

    window.addEventListener("scroll", interactedCheck);
    document.addEventListener("mousemove", interactedCheck);

    return () => {
      window.removeEventListener("scroll", interactedCheck);
      document.removeEventListener("mousemove", interactedCheck);
    };
  }, []);

  const checkAC = useCallback(async () => {
    if (!isInteracted || advertisingCampaign || !setAdvertisingCampaign) return;
    try {
      const resp = await fetch("/api/ac", { cache: "no-store" });

      const campaign: StrapiPromo = await resp.json();

      if (!campaign?.id) return;

      setAdvertisingCampaign(campaign);
    } catch (err) {}
  }, [advertisingCampaign, setAdvertisingCampaign, isInteracted]);

  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    checkAC();
  }, [checkAC]);

  useEffect(() => {
    if (!advertisingCampaign) return;
    const storageData = localStorage.getItem(`holiday_${advertisingCampaign.id}`);
    setIsEnabled(storageData !== "closed");
  }, [advertisingCampaign]);

  const onClick = () => {
    window.location.href = `https://app.${baseUrl}/order?discountCode=${
      advertisingCampaign?.attributes.code || ""
    }`;
  };

  const bannerSrc = advertisingCampaign?.attributes?.banner?.data?.attributes?.url || "";

  const onClose = (e: MouseEvent) => {
    if (!advertisingCampaign) return;

    localStorage.setItem(`holiday_${advertisingCampaign.id}`, "closed");
  };

  return isInteracted && !!advertisingCampaign && isEnabled ? (
    <HolidayBannerCard bannerSrc={bannerSrc} onClick={onClick} onClose={onClose} />
  ) : null;
};
