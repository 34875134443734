import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/store/store";

type GlobalState = {
  userInteracted: boolean;
  bodyOverflow: boolean;
  isRedirecting: boolean;
};

const initialState: GlobalState = {
  userInteracted: false,
  bodyOverflow: false,
  isRedirecting: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setInteracted: (state) => {
      state.userInteracted = true;
    },
    setBodyOverflow(state, { payload }) {
      state.bodyOverflow = payload;
    },
    setIsRedirecting: (state, action: PayloadAction<boolean>) => {
      state.isRedirecting = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInteracted, setBodyOverflow, setIsRedirecting } = globalSlice.actions;

export default globalSlice.reducer;

// Selectors
export const userInteractedSelector = ({ global }: RootState) => global.userInteracted;

export const isRedirectionSelector = ({ global }: RootState): boolean => global.isRedirecting;
