'use client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadChatwootScript } from '@/store/actions/chatwootActions';
import { usePathname } from 'next/navigation';

export const ChatwootWidget = () => {
  const dispatch = useDispatch();

  const pathname = usePathname();

  const getTitleFromPath = (path) => {
    const upperFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    if (!String(path).trim()) return '';
    const res = path.replace(/\//gi, ' ').trim();
    return upperFirst(res.trim());
  };

  const logPathToChatwoot = () => {
    const { origin } = window.location;

    const path = {
      url: `${origin}${pathname}`,
      title: `🏠 ${getTitleFromPath(pathname) || 'Home'}`,
    };

    window['$chatwoot']?.setCustomAttributes?.(path);
  };

  useEffect(() => {
    if (!localStorage.getItem('chatwoot')) return;
    console.log('Chatwoot > Auto init enabled');
    dispatch(loadChatwootScript());
  }, [dispatch]);

  useEffect(logPathToChatwoot, [pathname]);

  return null;
};
