import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { ThunkDispatch } from "redux-thunk";
import chatwoot from "./slices/chatwootSlice";
import faq from "./slices/faqSlice";
import global from "./slices/globalSlice";
import samples from "./slices/samplesSlice";
import { OrderFormSliceReducer } from "@/lib/priceCalculator";

export const store = configureStore({
  reducer: {
    global,
    chatwoot,
    samples,
    faq,
    orderForm: OrderFormSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type LocalDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
export type AppGetState = () => RootState;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = (): LocalDispatch => useDispatch<LocalDispatch>();
