"use client";
import { useInteraction } from "@/helpers/useInteraction";
import { GA_CODE } from "@/const";
import Script from "next/script";

/**
 * Google TagManager (aka Google Analytics)
 */
export const GoogleTagManager = () => {
  const { isInteracted } = useInteraction();

  if (!GA_CODE) return null;

  if (isInteracted)
    return (
      <>
        <Script id="gtm-1" src={`https://www.googletagmanager.com/gtag/js?id=${GA_CODE}`} />
        <Script
          id="gtm-2"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
                     function gtag(){dataLayer.push(arguments);}
                     gtag('js', new Date());
                     gtag('config', '${GA_CODE}');`,
          }}
        />
      </>
    );
  return null;
};
