'use client';
import Image from 'next/legacy/image';
import { useSelector } from 'react-redux';
import { ChatwootBtn } from './ChatwootBtn';

import styles from './ChatwootFallback.module.scss';

export const ChatwootFallback = () => {
  const { initialized } = useSelector(({ chatwoot }) => chatwoot);
  const ready = initialized || (typeof window !== 'undefined' && localStorage.getItem('chatwoot'));

  if (ready) return null;

  return (
    <button type="button" className={styles.btn}>
      <ChatwootBtn />
      <Image src="/img/misc/chat-placeholder.svg" alt="zopim" width={157} height={48} />
    </button>
  );
};
