"use client";

import { Provider } from "react-redux";
import { store } from "@/store/store";
import { AppContext } from "@/helpers/contexts/AppContext";
import { PropsWithChildren, useState } from "react";
import { StrapiGlobalData } from "@/types/strapi/global";
import { AdvertingCampaign } from "@/types/shared";
import { useViewPortHeight } from "@/helpers/useViewportHeight";

type ProvidersProps = PropsWithChildren<{
  global: StrapiGlobalData;
}>;

export function Providers({ children, global }: ProvidersProps) {
  const [advertisingCampaign, setAdvertisingCampaign] = useState<AdvertingCampaign | null>(null);

  useViewPortHeight();

  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{
          ...global,
          advertisingCampaign,
          setAdvertisingCampaign,
        }}
      >
        {children}
      </AppContext.Provider>
    </Provider>
  );
}
