"use client";
import s from "./CookiesNotification.module.scss";
import Link from "next/link";
import Image from "next/image";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Button } from "@/components/UI/Buttons/Button";
export const CookiesNotification = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const cookieSet = Cookies.get("acceptCookies");
    setVisible(!cookieSet);
  }, []);

  const handleClick = () => {
    Cookies.set("acceptCookies", "true");
    setVisible(false);
  };

  return visible ? (
    <div className={s.wrapper}>
      <Image src="/img/cookies.png" alt="cookie" className={s.img} width={60} height={60} />
      <p>
        By staying on this website, <br />
        you consent to our <Link href="/cookies-policies">use of cookies</Link>.
      </p>
      <Button type="white" onClick={handleClick} className={s.close} outline>
        I consent
      </Button>
    </div>
  ) : null;
};

export default CookiesNotification;
