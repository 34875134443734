import { createSlice } from "@reduxjs/toolkit";

export const faqSlice = createSlice({
  name: "faq",
  initialState: {
    allItems: [],
    filteredItems: [],
    searchQuery: "",
  },
  reducers: {
    set(state, { payload }) {
      state.allItems = payload;
    },
    search(state, { payload }) {
      state.searchQuery = payload;
      state.filteredItems = state.allItems.filter(({ heading, content }: any) => {
        return (
          !!heading.toLowerCase().match(
            state.searchQuery
              .trim()
              .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
              .toLowerCase()
          ) ||
          !!content.toLowerCase().match(
            state.searchQuery
              .trim()
              .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
              .toLowerCase()
          )
        );
      });
    },
    filter(state) {
      state.filteredItems = state.allItems.filter(
        ({ heading, content }: any) => !!heading.toLowerCase().match(state.searchQuery.toLowerCase())
      );
    },
    clear(state) {
      (state.searchQuery = ""), (state.filteredItems = []);
    },
  },
});

export default faqSlice.reducer;
